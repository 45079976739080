<template>
  <div>
    <el-form inline :model="formInline" class="demo-form-inline">
      <el-form-item label="up主id">
        <el-input
          v-model="formInline.vlogger_id"
          placeholder="up主id"
        ></el-input>
      </el-form-item>
      <el-form-item label="产品id">
        <el-select v-model="formInline.pro_id" placeholder="产品id">
          <el-option
            :label="item.product_name"
            :key="item.product_id"
            :value="item.product_id"
            v-for="item in projectList"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品用户id">
        <el-input
          v-model="formInline.pro_user_id"
          placeholder="产品用户id"
        ></el-input>
      </el-form-item>
      <el-button type="primary" @click="search">搜索</el-button>
      <el-button
        type="success"
        v-has="'/api/vlogger/product/create'"
        @click="
          addFrom = !addFrom;
          dialogType = 'add';
          data = {};
        "
        >添加</el-button
      >
      <el-button @click="refesh">刷新</el-button>
    </el-form>

    <el-table
      :data="tableData"
      border
      style="width: 100%"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
    >
      <el-table-column
        prop="vlogger_id"
        label="up主id"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="vlogger.account"
        label="up主"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="product.product_name"
        label="产品id"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pro_user_id"
        label="产品用户id"
        align="center"
      ></el-table-column>
      <el-table-column prop="enable" label="是否生效" align="center">
        <template slot-scope="scope">
          <el-switch
            @change="updateData(scope.row)"
            v-model="scope.row.enable"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        align="center"
      ></el-table-column>

      <el-table-column fixed="right" align="center" label="操作" width="130">
        <template slot-scope="scope">
          <el-button
            @click="edit(scope.row, 'disabled')"
            type="text"
            size="small"
            >查看</el-button
          >
          <el-button
            v-has="'/api/vlogger/product/update'"
            @click="edit(scope.row)"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            v-has="'/api/vlogger/product/destroy'"
            @click="deleteData(scope.row)"
            type="text"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <div class="page">
        <el-pagination
          :page-sizes="[10, 25, 50, 100]"
          :current-page="formInline.page"
          :page-size="formInline.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          v-if="total > 10"
          @size-change="changeSize"
          @current-change="changeCurrent"
        ></el-pagination>
      </div>
    </div>
    <addForm
      :show="addFrom"
      :dialogType="dialogType"
      :disabledType="disabled"
      :projectList="projectList"
      @clearData="clearData"
      :data="data"
      :userList="userList"
    ></addForm>
  </div>
</template>

<script>
import API from "@/api";
import addForm from "./components/addForm.vue";

export default {
  name: "association",
  components: { addForm },
  data() {
    return {
      data: {},
      loading: false,
      disabled: false,
      addFrom: false,
      projectList: [],
      userList: [],
      tableData: [],
      formInline: {
        page: 1,
        size: 10,
      },
      dialogType: "add",
      total: 0,
      isRefesh: true,
    };
  },
  created() {
    API.getProductList({ page: 1, size: 999 }).then((res) => {
      this.projectList = res.data.data;
    });
    API.getUpListData({ page: 1, size: 999, account_type: "vlogger" }).then(
      (res) => {
        this.userList = res.data.data;
      }
    );
    let listRule = "/api/vlogger/product/list";
    let obj = JSON.parse(
      JSON.parse(JSON.stringify(localStorage.getItem("btnPower")))
    );
    console.log(obj[listRule]);
    if (obj[listRule]) {
      this.isRefesh = true;
      this.getDataList();
    } else {
      this.isRefesh = false;
    }
  },

  methods: {
    refesh() {
      this.isRefesh ? this.getDataList() : "";
    },
    search() {
      this.formInline.page = 1;
      this.getDataList();
    },
    closeAdd() {
      this.addFrom = false;
      this.getDataList();
    },
    edit(data, type) {
      type ? (this.dialogType = "look") : (this.dialogType = "edit");
      this.addFrom = true;
      this.data = JSON.parse(JSON.stringify(data));
      this.disabled = !!type;
    },
    async getDataList() {
      this.loading = true;
      const { data, code } = await API.getUpProjectList(this.formInline);
      if (!code) {
        this.tableData = data.data;
        this.total = data.pagination.count;
        this.tableData.forEach((item) => {
          const timestampCreate = item.create_time * 1000;
          const dateCr = new Date(timestampCreate);
          item.create_time = dateCr.toLocaleString();
        });
        this.loading = false;
      } else {
        this.tableData = [];
        this.total = 0;
        this.loading = false;
      }
    },
    async updateData(row) {
      console.log("row", row);
      await API.updateUserV2(row).then(() => {
        this.getDataList();
      });
    },
    clearData() {
      this.addFrom = false;
    },
    // 分页条数改变回调
    changeSize(size) {
      this.formInline.size = size;
      this.getDataList();
    },
    // 分页页码改变回调
    changeCurrent(number) {
      this.formInline.page = number;
      this.getDataList();
    },
    deleteData(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.deleteUpAss({ id: data.id }).then((res) => {
          if (!res.code) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getDataList();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.action-bar {
  display: flex;
}
</style>
